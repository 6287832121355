.custom-rate .ant-rate-star {
    font-size: 30px; /* Aumente o valor para o tamanho desejado */

    @media screen and (max-width: 600px) {
        font-size: 24px;
    }
}

/* HTML: <div class="loader"></div> */
.loaderCommmentarys {
    font-weight: bold;
    font-family: sans-serif;
    font-size: 14px;
    animation: l1 1s linear infinite alternate;
}
.loaderCommmentarys:before {
    content: 'Carregando as mensagens...';
}
@keyframes l1 {
    to {
        opacity: 0;
    }
}
